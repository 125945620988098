var SITE = (function ($, window, document, undefined) {
    'use strict';

	/**
	 * Initialize the hero carousel on the Hompage
	 */
	function _home() {
		var $hero = $( '.section-hero' );
		var $slider = $hero.find( '.slider' );

		$slider.slick({
			'arrows': false,
			'dots': true
		});
	}

	/**
	 * Initialize the mobile navigation
	 */
	function _mobile() {
		// Cache the mobile navigation button
		var $mobileNavBtn = $( '#navToggle' );

		$mobileNavBtn.on( 'click', function( e ) {
			e.preventDefault();
			document.body.classList.toggle( 'mobile-nav-opened' );
		});

		// Bind an event on keyup to detect the Escape key.
		// If pressed while the navigation menu is opened, close it
		$( document ).on( 'keyup', function( e ) {
			if ( e.keyCode === 27 && document.body.classList.contains('mobile-nav-opened') ) {
				$mobileNavBtn.trigger( 'click' );
			}
		});
	}

	$(function () {
		if ( document.body.classList.contains('home') ) {
			_home();
		}

		_mobile();
	});

    return {

    }


} (jQuery, window, document));